<div class="footer">
    <div class="tw-flex tw-gap-4">
        <div>Powered By PayInvoice</div>
        <div>Version 1.00.0</div>
    </div>
    <div>
        &copy; Supplier Connect is copyright of Regime Tax Solutions Private Limited
    </div>
    <div>
        Last Account Activity: 5 minutes ago
    </div>
</div>