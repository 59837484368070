import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidenav-item',
  standalone: true,
  imports: [],
  templateUrl: './sidenav-item.component.html',
  styleUrl: './sidenav-item.component.scss'
})
export class SidenavItemComponent {
  @Input() cssClass?: string;
}
