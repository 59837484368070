export const LOGO = {
    LOGO: 'assets/images/sidenav/logo-supplier.svg'
}
export const SIDENAV_IMAGES = {

    DASHBOARD: 'assets/images/sidenav/dashboard.svg',
    DASHBOARD_ACTIVE: 'assets/images/sidenav/dashboard-active.svg',
    
    RECEIVABLE: 'assets/images/sidenav/receivable.svg',
    RECEIVABLE_ACTIVE: 'assets/images/sidenav/receivable-active.svg',
    
    RECONCILIATION: 'assets/images/sidenav/reconcilliation.svg',
    RECONCILIATION_ACTIVE: 'assets/images/sidenav/reconcilliation-active.svg',

    GST_FILING: 'assets/images/sidenav/GST-filing.svg',
    GST_FILING_ACTIVE: 'assets/images/sidenav/GST-filing-active.svg',

    MASTER_DATA: 'assets/images/sidenav/master-data-mgt.svg',
    MASTER_DATA_ACTIVE: 'assets/images/sidenav/master-data-mgt-active.svg',

    REPORT: 'assets/images/sidenav/report.svg',
    REPORT_ACTIVE: 'assets/images/sidenav/report-active.svg',

    DOCUMENTS: 'assets/images/sidenav/documents.svg',
    DOCUMENTS_ACTIVE: 'assets/images/sidenav/documents-active.svg',
    
}