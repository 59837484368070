import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  private loaderState = new BehaviorSubject<boolean>(false);
  private progressState = new BehaviorSubject<number>(0);

  constructor() { }

  show() {
    this.loaderState.next(true);
  }

  hide() {
    this.loaderState.next(false);
  }

  setProgress(progress: number) {
    this.progressState.next(progress);
  }

  getLoaderState() {
    return this.loaderState.asObservable();
  }

  getProgressState() {
    return this.progressState.asObservable();
  }
}