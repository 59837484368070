import { Component, ViewChild, inject } from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { FeatherModule } from 'angular-feather';
import { SidenavItemComponent } from 'src/app/pages/dashboard/sidenav-item/sidenav-item.component';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LOGO, SIDENAV_IMAGES } from 'src/app/constants/images/sidenav.constant';
import { ROUTES_URL } from 'src/app/constants/routes/routes.constant';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-container',
  standalone: true,
  imports: [
    RouterModule,
    MatSidenavModule,
    FeatherModule,
    SidenavItemComponent,
    HeaderComponent,
    FooterComponent,
    CommonModule,
    MatTooltipModule,
    MatIconModule,
    FontAwesomeModule
  ],
  templateUrl: './app-container.component.html',
  styleUrl: './app-container.component.scss'
})
export class AppContainerComponent {
  router: Router = inject(Router);
  routes: ActivatedRoute = inject(ActivatedRoute);
  SIDENAV_IMAGES = SIDENAV_IMAGES;
  ROUTE_URL = ROUTES_URL;
  LOGO = LOGO;
  companyName:any
  constructor(private auth:AuthService){
    this.auth.companyName$.subscribe((data:string)=>{
    this.companyName=data
    })
  }

  redirectByURL(url: string = this.ROUTE_URL.DASHBOARD) {
    this.router.navigateByUrl(url);
  }

  isRouteActive(route: string): string {
    return this.router.isActive(route, true) ? 'sidenav-item-container--active' : '';
  }
  @ViewChild('fullSidenav') fullSidenav!: MatSidenav;
  @ViewChild('collapsedSidenav') collapsedSidenav!: MatSidenav;

  toggleSidenav() {
    this.fullSidenav.toggle();
    this.collapsedSidenav.toggle();
  }
  getSidenavWidth(): number {
    return this.fullSidenav?.opened ? this.fullSidenav?._getWidth() : this.collapsedSidenav?._getWidth();
  }


}
