<div class="header-container">

    <!-- breadcrumbs here -->
    <app-breadcrumb></app-breadcrumb>

    <!-- Right side section -->
    <div class="header-container__settings">
        <!-- <div class="search-input-container">
            <img [src]="HEADER_IMAGES.SEARCH" class="search-icon" alt="" srcset="">
            <input class="search-input" placeholder="Search">
        </div> -->
        <div>
            <div [matMenuTriggerFor]="company" class="c-dropdown" >
              <span>{{currentCompanyName()}}</span>
              <img [src]="HEADER_IMAGES.ARROW" alt="" srcset="">
            </div>
            <mat-menu #company="matMenu" Class="currencyMenu" >
            @for(org of organisations; track $index){
                <span mat-menu-item (click)="companyId() != org.organisationId ? changeCurrentCompany(org.organisationId) : null">
                    {{org.organisationName}}
                    <!-- @if(companyId() === org.organisationId){
                        <img [src]="HEADER_IMAGES.GREEN_CHECK" alt="" srcset="">
                    } -->
                </span>
            }
            </mat-menu>
        </div>

        <div>
          <div [matMenuTriggerFor]="currency" class="c-dropdown" >
            <span>{{ currencyType }} </span>
            <img [src]="HEADER_IMAGES.ARROW" alt="" srcset="">
          </div>
          <mat-menu #currency="matMenu" Class="currencyMenu" >
            <span mat-menu-item (click)="changeCurrency('None')">None</span>
            <span mat-menu-item (click)="changeCurrency('Lakhs')">Lakhs</span>
            <span mat-menu-item (click)="changeCurrency('Crore')">Crore</span>
            <span mat-menu-item (click)="changeCurrency('Millions')">Millions</span>
          </mat-menu>
        </div>





        <div class="header-container__settings-item">
            <a [routerLink]="ROUTE_URL.SETTINGS">
                <img [src]="HEADER_IMAGES.SETTINGS" alt="" srcset="">
            </a>
        </div>

        <!-- <div class="header-container__settings-item">
            <img [src]="HEADER_IMAGES.NOTIFICATION" alt="" srcset="">
        </div> -->

        <div>
            @if(profileImgUrl()){
            <div [matMenuTriggerFor]="profileSection">
                <img class="header-container__profile" [src]="profileImgUrl()" alt="">
            </div>
            }@else{
            <div [matMenuTriggerFor]="profileSection" class="header-container__profile--not-found">
                <img [src]="HEADER_IMAGES.PROFILE_NOT_FOUND" alt="">
            </div>
            }

            <mat-menu #profileSection="matMenu" class="profile-menu">
                <div class="tw-flex tw-flex-col primary-text-color tw-text-sm" (click)="$event.stopPropagation()">
                    <div class="tw-py-2 tw-px-3 profile-background">
                        <div class="tw-flex tw-flex-col tw-items-center">
                            @if(profileImgUrl()){
                            <div [matMenuTriggerFor]="profileSection">
                                <img class="header-container__modal-profile" [src]="profileImgUrl()" alt="">
                            </div>
                            }@else{
                            <div [matMenuTriggerFor]="profileSection"
                                class="header-container__modal-profile--not-found">
                                <img [src]="HEADER_IMAGES.PROFILE_NOT_FOUND" alt="">
                            </div>
                            }
                            <div class="tw-font-black tw-pb-2 tw-pt-1">{{firstName()}} {{lastName()}}</div>
                            <div>User ID: {{userId()}}</div>
                            <div>Organization ID: {{companyId()}}</div>
                            <div>{{userEmail()}}</div>
                            <div class="tw-pt-2">
                                <button class="sc-btn sc-btn__link">My Account</button> |
                                <button class="sc-btn sc-btn__red" (click)="signOut()">Sign Out</button>
                            </div>
                        </div>
                    </div>
                    <div class="tw-py-2 tw-px-3 all-companies__container">
                        <!-- <div>MY ORGANZATIONS</div> -->
                        <div class="all-companies tw-py-2 tw-font-bold">All Companies</div>
                            @for(org of organisations; track $index){
                            <div class="tw-py-2 tw-flex tw-gap-2 tw-items-center organisations">
                                <div class="tw-grow" (click)="companyId() != org.organisationId ? changeCurrentCompany(org.organisationId) : null">
                                    <div>{{org.organisationName}}</div>
                                    <div>Organization ID: {{org.organisationId}}</div>
                                    <div>{{org.organisationType}}</div>
                                    <div>PAN: {{org.pan}}</div>
                                </div>
                                <div>
                                    @if(companyId() === org.organisationId){
                                        <img [src]="HEADER_IMAGES.GREEN_CHECK" alt="" srcset="">
                                    }
                                </div>
                            </div>
                            }
                    </div>
                </div>
            </mat-menu>
        </div>
    </div>
</div>
