import { IndianCurrencyFormatService } from 'src/app/core/services/currency/indian-currency-format.service';
import { Component, inject, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { FeatherModule } from 'angular-feather';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';

import { HEADER_IMAGES } from 'src/app/constants/images/header.constant';
import { MatMenuModule } from '@angular/material/menu';
import { IOrganisations } from 'src/app/core/models/dashboard/header.model';
import { ROUTES_URL } from 'src/app/constants/routes/routes.constant';
import { jwtDecode } from 'jwt-decode';
import { IJwtToken } from 'src/app/core/models/authentication/jwt/jwt.model';
import { LoginServiceService } from 'src/app/core/services/httpcalls/login-service.service';
import { CompanyInfo, CompanyInfoResponse, IChangeCurrentCompany, IJwtTokenResp, IUserCompany, IUserProfileImage, IUserProfileResponse } from 'src/app/core/models/user/user.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment.development';
import { ENV_VARIABLES } from 'src/app/constants/API/env-variables';
import { UsersService } from 'src/app/core/services/httpcalls/users.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [FeatherModule, BreadcrumbComponent, MatMenuModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  profileImgUrl = signal<SafeUrl>('');
  HEADER_IMAGES = HEADER_IMAGES;
  ROUTE_URL = ROUTES_URL;
  router: Router = inject(Router);
  routes: ActivatedRoute = inject(ActivatedRoute);
  _sanitizer: DomSanitizer = inject(DomSanitizer);
  _userAPIService: LoginServiceService = inject(LoginServiceService);
  organisations: IOrganisations[] = [];

  userId = signal<number | null>(null);
  companyId = signal<number | null>(null);
  userEmail = signal<string>("");
  firstName = signal<string>("");
  lastName = signal<string>("");
  currentCompanyName = signal<string>("");

  jwtToken: string = localStorage.getItem('jwtToken') ?? '';
  userHttpCalls: UsersService = inject(UsersService);
  toastr: ToastrService = inject(ToastrService);
  currencyType = 'Numbering System';

  constructor(private indianCurrrencyFormat:IndianCurrencyFormatService,private auth:AuthService) {
    const decodedToken = jwtDecode<IJwtToken>(this.jwtToken);
    this.userId.set(decodedToken?.userId);
    this.companyId.set(decodedToken?.companyId);
    this.userEmail.set(decodedToken?.sub);
    localStorage.setItem("companyId", decodedToken?.companyId.toString());
    localStorage.setItem("userID", decodedToken?.userId.toString());
    this.getUserDetails(this.userId());
    // this.getUserProfileImage(this.userEmail());
    this.getAllCompaniesForuser(this.userEmail());

  }
  signOut() {
    localStorage.removeItem("jwtToken");
    localStorage.clear();
    this.router.navigateByUrl("/");
  }

  getAllCompaniesForuser(userName: string) {
    if (userName)
      this._userAPIService.getCompanyForUser(userName, Number.parseInt(ENV_VARIABLES.PRODUCT_ID)).subscribe((userCompanies: CompanyInfoResponse) => {
        if (userCompanies.length > 0) {
          this.organisations = userCompanies.map((company: CompanyInfo) => {
            if(company.companyId == this.companyId()) {
              this.currentCompanyName.set(company.companyName);
            }
            this.auth.companyName$.next(company.companyName)
            return {
              organisationId: company.companyId,
              organisationName: company.companyName,
              organisationType: company.companyType,
              pan:company.panNumber,
              isOwnOrganisation: this.companyId() == company.companyId
            }
          })
          this.organisations.push();
          
        }
      })
  }

  getUserDetails(userId: number | null) {
    if (userId)
      this._userAPIService.getUserProfile(userId).subscribe((userDetails: IUserProfileResponse) => {
        this.firstName.set(userDetails.firstName);
        this.lastName.set(userDetails.lastName);
      })
  }

  getUserProfileImage(userName: string) {
    if (userName)
      this._userAPIService.getUserProfileImage(userName).subscribe((userProfileImage: IUserProfileImage) => {
        if (userProfileImage.userPhoto) {
          const objectURL = 'data:image/png;base64, ' + userProfileImage.userPhoto;
          this.profileImgUrl.set(this._sanitizer.bypassSecurityTrustUrl(objectURL));
        }
      })
  }
  changeCurrentCompany(companyId: number | null) {
    const changeCurrentCompanyReq: IChangeCurrentCompany = {
      authenticationRequest: {
        jwttoken: this.jwtToken,
        productId: Number.parseInt(ENV_VARIABLES.PRODUCT_ID),
        username: this.userEmail()
      },
      companyId: companyId
    };

    this.organisations.forEach((organisation) => {
      if(organisation.organisationId == companyId) {
        this.currentCompanyName.set(organisation.organisationName);
      }
    });

    // Call CAM API to get the new Token for new company
    this._userAPIService.changeCurrentCompany(changeCurrentCompanyReq)
      .subscribe((resp: IJwtTokenResp) => {
        localStorage.setItem("jwtToken", resp.jwt);
        this.jwtToken = resp.jwt;
        const decodedToken = jwtDecode<IJwtToken>(this.jwtToken);
        this.userHttpCalls.getCompaniesByUser(this.userEmail(), decodedToken?.companyId, null).subscribe(data => {
          const supplierGstIn = data.gstInList[0];
          localStorage.setItem("supplierId", Object.keys(data.companyList)[0]);
          localStorage.setItem("supplierGstIn", supplierGstIn != null ? supplierGstIn.toString() : '');
          localStorage.setItem("supplierCompanyMailId",Object.values(data.companyList)[0]);
          localStorage.setItem("pan", data.pan);
          localStorage.setItem("companyMasterId", data.companyMasterId != null ? data.companyMasterId.toString() : '');
          localStorage.removeItem("api4BusinessToken");
          this.toastr.success("Successfully switched company");
          window.location.reload();
        });

      },
      error => {
        if(error.status == 401) {
          this.signOut();
        }
      })

  }



  changeCurrency(value: string) {
    this.currencyType = value  ;
    this.indianCurrrencyFormat.setCurrencyType$.next(this.currencyType);

  }
}
