<mat-sidenav-container>
    <mat-sidenav #collapsedSidenav mode="side" opened="false" class="collapsed-sidenav">
        <div class="tw-my-6 tw-mx-4">
            <div class="logo-container">
                <img [src]="LOGO.LOGO" alt="">
            </div>

            <div class="app-container">
                <ul class="collapsed-sidebar-container">
                    <li class="tw-cursor-pointer">
                        <a [routerLink]="ROUTE_URL.DASHBOARD">
                            <span [ngClass]="isRouteActive(ROUTE_URL.DASHBOARD)" matTooltip="Dashboard"
                                matTooltipPosition="right" matTooltipClass="customTooltip"
                                class="collapsed-sidenav-item-container">
                                @if(isRouteActive(ROUTE_URL.DASHBOARD)){
                                <img [src]="SIDENAV_IMAGES.DASHBOARD_ACTIVE" id="icon" class="sc-icon" alt="" srcset="">
                                }
                                @else{
                                <img [src]="SIDENAV_IMAGES.DASHBOARD" id="icon" class="sc-icon" alt="" srcset="">
                                }
                            </span>



                            <!-- </app-sidenav-item> -->
                        </a>
                    </li>
                    <li class="tw-cursor-pointer">
                        <a [routerLink]="ROUTE_URL.RECEIVABLE">
                            <span [ngClass]="isRouteActive(ROUTE_URL.RECEIVABLE)" matTooltip="Receivable"
                                matTooltipPosition="right" matTooltipClass="customTooltip"
                                class="collapsed-sidenav-item-container">
                                @if(isRouteActive(ROUTE_URL.RECEIVABLE)){
                                <img [src]="SIDENAV_IMAGES.RECEIVABLE_ACTIVE" id="icon" class="sc-icon" alt=""
                                    srcset="">
                                }
                                @else{
                                <img [src]="SIDENAV_IMAGES.RECEIVABLE" id="icon" class="sc-icon" alt="" srcset="">
                                }
                            </span>

                            <!-- </app-sidenav-item> -->
                        </a>
                    </li>
                    <li class="jsm-container jsm-container-small">
                        <a href="https://taxgenie.atlassian.net/servicedesk/customer/portal/22" target="_blank"><img
                                src="../../../assets/images/dashboard/Question-mark-withput-bg.svg" id="icon"
                                class="sc-icon" alt="" srcset=""></a>
                    </li>
                </ul>
            </div>
            <button mat-icon-button class="toggle-button" (click)="toggleSidenav()">
                <img src="../../../assets/images/dashboard/caret-right.svg">
            </button>
        </div>

        <!-- Add any other content you want to be visible when the sidenav is collapsed -->
    </mat-sidenav>
    <mat-sidenav disableClose="true" mode="side" opened="true" #fullSidenav class="full-sidenav">
        <div class="tw-my-6 tw-mx-4">
            <div class="logo-container">
                <img [src]="LOGO.LOGO" alt="">
                <div class="logo">Supplier Connect</div>
            </div>

            <div class="app-container">
                <ul>
                    <li class="tw-cursor-pointer">
                        <a [routerLink]="ROUTE_URL.DASHBOARD">
                            <app-sidenav-item [cssClass]="isRouteActive(ROUTE_URL.DASHBOARD)">
                                @if(isRouteActive(ROUTE_URL.DASHBOARD)){
                                <img [src]="SIDENAV_IMAGES.DASHBOARD_ACTIVE" id="icon" class="sc-icon" alt="" srcset="">
                                }
                                @else{
                                <img [src]="SIDENAV_IMAGES.DASHBOARD" id="icon" class="sc-icon" alt="" srcset="">
                                }
                                <span class="heading-text" id="text">Dashboard</span>
                            </app-sidenav-item>
                        </a>
                    </li>
                    <li class="tw-cursor-pointer">
                        <a [routerLink]="ROUTE_URL.RECEIVABLE">
                            <app-sidenav-item [cssClass]="isRouteActive(ROUTE_URL.RECEIVABLE)">
                                @if(isRouteActive(ROUTE_URL.RECEIVABLE)){
                                <img [src]="SIDENAV_IMAGES.RECEIVABLE_ACTIVE" id="icon" class="sc-icon" alt=""
                                    srcset="">
                                }
                                @else{
                                <img [src]="SIDENAV_IMAGES.RECEIVABLE" id="icon" class="sc-icon" alt="" srcset="">
                                }
                                <span class="heading-text" id="text">Receivable</span>
                            </app-sidenav-item>
                        </a>
                    </li>
                    <li class="jsm-container">

                        <img src="../../../assets/images/dashboard/background-jsm.svg" alt="" srcset="">
                        <div class="overlay">
                            <img src="../../../assets/images/dashboard/question-mark-with-bg.svg">
                            <div class="big-font">Have something to share?</div>
                            <div class="small-font">We would love to hear from you</div>
                            <div class="links">
                                <a href="https://taxgenie.atlassian.net/servicedesk/customer/portal/22/group/30/create/147?customfield_10140={{companyName}}"
                                    target="_blank">Report a bug</a>
                                <a href="https://taxgenie.atlassian.net/servicedesk/customer/portal/22/group/30/create/168?customfield_10140={{companyName}}"
                                    target="_blank">Data Syncing</a>
                                <a href="https://taxgenie.atlassian.net/servicedesk/customer/portal/22/group/30/create/148?customfield_10140={{companyName}}"
                                    target="_blank">Suggest
                                    Improvement</a>
                                <a href="https://taxgenie.atlassian.net/servicedesk/customer/portal/22/group/30/create/149?customfield_10140={{companyName}}"
                                    target="_blank">Suggest a new
                                    feature</a>
                                <a href="https://taxgenie.atlassian.net/servicedesk/customer/portal/22/group/30/create/169?customfield_10140={{companyName}}"
                                    target="_blank">Change Request</a>    
                            </div>
                        </div>
                    </li>
                    <!-- <li>
                    <app-sidenav-item>
                        <img [src]="SIDENAV_IMAGES.RECONCILIATION" id="icon" class="sc-icon" alt="" srcset="">
                        <span class="tw-opacity-50" id="text">Reconciliation</span>
                    </app-sidenav-item>
                </li>
                <li>
                    <app-sidenav-item>
                        <img [src]="SIDENAV_IMAGES.GST_FILING" id="icon" class="sc-icon" alt="" srcset="">
                        <span class="tw-opacity-50" id="text">GST Filing</span>
                    </app-sidenav-item>
                </li>
                <li>
                    <app-sidenav-item>
                        <img [src]="SIDENAV_IMAGES.MASTER_DATA" id="icon" class="sc-icon" alt="" srcset="">
                        <span class="tw-opacity-50" id="text">Master Data Mgt</span>
                    </app-sidenav-item>
                </li>
                <li>
                    <app-sidenav-item>
                        <img [src]="SIDENAV_IMAGES.REPORT" id="icon" class="sc-icon" alt="" srcset="">
                        <span class="tw-opacity-50" id="text">Reports</span>
                    </app-sidenav-item>
                </li>
                <li>
                    <app-sidenav-item>
                        <img [src]="SIDENAV_IMAGES.DOCUMENTS" id="icon" class="sc-icon" alt="" srcset="">
                        <span class="tw-opacity-50" id="text">Documents</span>
                    </app-sidenav-item>
                </li> -->
                </ul>
            </div>
            <button mat-icon-button class="toggle-button" (click)="toggleSidenav()">
                <img src="../../../assets/images/dashboard/caret-left.svg">
            </button>
        </div>

    </mat-sidenav>

    <mat-sidenav-content [style.padding-left.px]="getSidenavWidth()" style="margin-left:0px">
        <!-- <div class="app-content-container">
            <app-header />
            <ng-content></ng-content>
        </div> -->
        <div class="app-content-container">
            <app-header />
            <!-- <button mat-button (click)="toggleSidenav()">Toggle Sidenav</button> -->
            <ng-content></ng-content>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
<app-footer />
