<!-- <div>
    @for (item of breadcrumbArray; track $index) {
        <span class="breadcrumb-title">
            @if ($index != 0) { / }
            @if (!item.isActive) { <a [routerLink] = "item.link" >{{ item.title }} </a> }
            @else { <span class="active-label">{{ item.title }}</span> }
        </span>
    }
</div> -->

<div>
    @for (item of breadcrumb; track $index) {
        <span class="breadcrumb-title">
            @if ($index != 0) { / }
            @if (!item.isActive) { <a [routerLink] = "item.link" >{{ item.title }} </a> }
            @else { <span class="active-label">{{ item.title }}</span> }
        </span>
    }
</div>


