import { Component, Input, inject } from '@angular/core';
import { NavigationStart, Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent {
  // @Input() breadcrumbArray: {title: string, link: string, isActive: boolean}[] = []; // Get array of all the breadcrumb headings
  breadcrumb: { title: string, link: string, isActive: boolean }[] = [];
  breadcrumbService: BreadcrumbService = inject(BreadcrumbService);
  private routerSubscription!: Subscription;
  private breadcrumbSubscription!:Subscription
  constructor(private router: Router){}
  ngOnInit() {
   this.breadcrumbSubscription= this.breadcrumbService.breadcrumb$.subscribe(breadcrumb => {
      this.breadcrumb = breadcrumb;
    });

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.breadcrumbService.clearBreadcrumb();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.breadcrumbSubscription) {
      this.breadcrumbSubscription.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
